import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const GenericPdfDownloader = ({ downloadFileName }) => {
  console.log(downloadFileName);
  const downloadPdfDocument = () => {
    // Capture the visible portion of the page
    // const input = document.documentElement;
    const input = document.querySelector("#invoice");

    // Set the size of the PDF to A4 dimensions (210mm x 297mm)
    // const pdfWidth = 210;
    // const pdfHeight = 297;

    // html2canvas(input, {
    //   windowWidth: document.documentElement.offsetWidth,
    //   windowHeight: document.documentElement.offsetHeight,
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/jpeg");
    //   const pdf = new jsPDF({
    //     orientation: "portrait", // You can use "landscape" for landscape orientation
    //     unit: "mm",
    //     format: [pdfWidth, pdfHeight],
    //   });

    //   pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    //   pdf.save(`${downloadFileName}.pdf`);
    // });

    html2canvas(input, {
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");

      // A4 size in mm (210 x 297)
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm

      // Get canvas dimensions in pixels
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Calculate aspect ratio
      const aspectRatio = imgWidth / imgHeight;

      // Calculate scaled dimensions to fit A4 page while maintaining aspect ratio
      // let renderWidth, renderHeight;
      // if (aspectRatio > 1) {
      //   // Landscape image
      //   renderWidth = pdfWidth;
      //   renderHeight = pdfWidth / aspectRatio;
      // } else {
      //   // Portrait image
      //   renderHeight = pdfHeight;
      //   renderWidth = pdfHeight * aspectRatio;
      // }

      let renderWidth = pdfWidth;
      let renderHeight = pdfWidth / aspectRatio;
      if (renderHeight > pdfHeight) {
        renderHeight = pdfHeight;
        renderWidth = pdfHeight * aspectRatio;
      }

      // Center the image on the page
      const x = (pdfWidth - renderWidth) / 2;
      const y = (pdfHeight - renderHeight) / 2;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4", // Automatically sets to 210x297 mm
      });

      pdf.addImage(imgData, "JPEG", x, 0, renderWidth, renderHeight);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return (
    <button
      onClick={downloadPdfDocument}
      className="bg-red-600 text-2xl font-bold rounded-xl p-2 text-white"
    >
      Download Invoice PDF
    </button>
  );
};

export default GenericPdfDownloader;
